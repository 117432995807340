import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Fade from "react-reveal/Fade"
import Breadcrumbs from "src/utils/Breadcrumbs"
import WoodsAndFinishes from "src/components/WoodsAndFinishes"
import BlockCta from "src/components/Blocks/BlockCta"
import BlockConfigurations from "src/components/Blocks/BlockConfigurations"

const InternalDoorsWoodsAndFinishes = ({
  data: { allPrismicWoodsAndFinishes },
}) => {
  const data = allPrismicWoodsAndFinishes

  const crumbs = [
    { label: "Internal Doors", href: "/internal-doors" },
    {
      label: "Woods & Colours",
      href: "/internal-doors/woods-finishes",
      active: true,
    },
  ]

  return (
    <main>
      <Seo 
        title={"Internal Door Colours and Finishes | Woods and Materials"} 
        description={"Our woods and finishes are the perfect solution to bringing an updated look and feel to a home. See our full range and reflect your style throughout your space."}
        />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <WoodsAndFinishes {...data} type="internal" />
      <section className={`bg-offwhite`}>
        <div className={`container`}>
          <div className={`flex flex-col space-y-12 lg:space-y-0 lg:flex-row`}>
            <div className="w-full lg:w-7/12">
              <div className="grid grid-cols-2 gap-4">
                <Fade>
                  <img
                    src={`https://images.prismic.io/deuren/bcdaea7b-f84e-4444-bf62-3bfaefeac3d8_bc257677-c6a6-4fad-8683-9f82aeeb3f87_deuren-internal-door-sets-define-your-style-1.jpg?auto=compress,format`}
                    alt={`Woods and Colours`}
                  />
                  <video className="w-full" autoPlay loop muted playInline>
                    <source
                      src={`https://d2jpqhjplyyyp0.cloudfront.net/Deuren-Woods.mp4`}
                    />
                  </video>
                </Fade>
              </div>
            </div>
            <div className={`w-full lg:w-5/12 lg:pl-20`}>
              <div className="sticky top-32 text-center lg:text-left">
                <Fade distance="20px" bottom>
                  <h1 className="font-display text-gold rfs:text-6xl">
                    Woods &amp; finishes for any door style
                  </h1>
                  <div className="w-12 mt-5 separator"></div>
                  <div className="mt-5">
                    <p>
                      Our woods and finishes are the perfect solution to
                      bringing an updated look and feel to a home. See our
                      contemporary or traditional door styles that will suit
                      your style and let your creativity run wild.
                    </p>
                  </div>
                  <Link
                    className="btn mt-6"
                    to={`/internal-doors/styles/`}
                    role="button"
                  >
                    See all internal door styles
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlockConfigurations props={`internal-doors`} />
      <BlockCta />
    </main>
  )
}

export default InternalDoorsWoodsAndFinishes

export const InternalDoorsWoodsAndFinishesQuery = graphql`
  query InternalDoorsWoodsAndFinishesQuery {
    allPrismicWoodsAndFinishes(
      filter: { data: { door_type: { eq: "Internal Door" } } }
    ) {
      edges {
        node {
          data {
            colour_type
            title {
              text
            }
            featured_image {
              url(imgixParams: { auto: "format" })
              alt
            }
            door_type
            description {
              richText
            }
            related_page {
              uid
            }
          }
        }
      }
    }
  }
`
